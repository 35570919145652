<!-- 
    页面 重置密码
-->
<template>
  <div id="reset-password-page">
    <div class="reset-password-header">
      <vx-card>
        <div class="table_height_container pt-5">
          <el-form :rules="rules" :model="form" ref="form" label-width="80px" style="width: 400px">
            <el-form-item label="用户名" prop="name">
              <el-input v-model="form.name" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="原密码" prop="password">
              <el-input v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
              <el-input v-model="form.newPassword"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="text" size="small" class="primary" @click="resetForm('form')">取消</el-button>
              <el-button type="text" size="small" class="primary" @click="submitForm('form')">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5'
export default {
  data() {
    var checkPassword = (rule, value, callback) => {
      let regex = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,20}/
      // 字母连续规则
      let strReg = /(a(?=b)|b(?=c)|c(?=d)|d(?=e)|e(?=f)|f(?=g)|g(?=h)|h(?=i)|i(?=j)|j(?=k)|k(?=l)|l(?=m)|m(?=n)|n(?=o)|o(?=p)|p(?=q)|q(?=r)|r(?=s)|s(?=t)|t(?=u)|u(?=v)|v(?=w)|w(?=x)|x(?=y)|y(?=z)|z(?=a)){3}[a-z]/i
      // 数字连续规则
      let numReg = /(0(?=1)|1(?=2)|2(?=3)|3(?=4)|4(?=5)|5(?=6)|6(?=7)|7(?=8)|8(?=9)|9(?=0)){3}\d/
      // 键盘字母横向连续规则
      let keyboardHorizontalReg = /(q(?=w)|w(?=e)|e(?=r)|r(?=t)|t(?=y)|y(?=u)|u(?=i)|i(?=o)|o(?=p)|p(?=q) |a(?=s)|s(?=d)|d(?=f)|f(?=g)|g(?=h)|h(?=j)|j(?=k)|k(?=l)|l(?=a) | z(?=x)|x(?=c)|c(?=v)|v(?=b)|b(?=n)|n(?=m)|m(?=z)){3}[a-z]/i
      // 多个相同字母、数字规则
      let sameReg = /(\w)\1{3}/i

      /**
       * 键盘斜线的4个字符是否在pwd里面
       */
      function isStrInSlope(pwd) {
        let keyboardSlopeArr = ['1qaz', '2wsx', '3edc', '4rfv', '5tgb', '6yhn', '7ujm', '8ik,', '9ol.', '0p;/', '=[;.', '-pl,', '0okm', '9ijn', '8uhb', '7ygv', '6tfc', '5rdx', '4esz']
        return keyboardSlopeArr.some(v => pwd.toLowerCase().indexOf(v) > -1)
      }

      if (regex.test(value)) {
        if (sameReg.test(value)) {
          callback(new Error('密码不能含有连续4位相同的数字或字母'))
        } else if (strReg.test(value)) {
          callback(new Error('密码不能含有4位连续的字母'))
        } else if (numReg.test(value)) {
          callback(new Error('密码不能含有4位连续的数字'))
        } else if (keyboardHorizontalReg.test(value)) {
          callback(new Error('密码不能含有4位键盘横向方向连续的字母'))
        } else if (isStrInSlope(value)) {
          callback(new Error('密码不能含有4位键盘斜向方向连续的字符'))
        }
        callback()
      }
      callback(new Error('您的密码复杂度太低（密码中必须包含字母、数字、特殊字符），请重新设置密码'))
    }
    return {
      form: {
        userID: '',
        name: '',
        password: '',
        newPassword: ''
      },
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.modifyUser()
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    logout() {
      const payload = {
        router: this.$router
      }
      this.$store.dispatch('auth/loginOut', payload)
    },
    async modifyUser() {
      try {
        let params = {
          userID: this.form.userID,
          oldpwd: md5(this.form.password),
          newpwd: md5(this.form.newPassword)
        }
        let result = await this.$apis.ODC('重置当前用户密码', params)
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, 'success', this.$apis.getResMessage(result))
          this.logout()
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    }
  },
  created() {
    this.form.name = JSON.parse(localStorage.getItem('userInfo')).displayName
    this.form.userID = JSON.parse(localStorage.getItem('userInfo')).userID
  }
}
</script>

<style lang="scss" scope="scope">
#reset-password-page {
  .table_height_container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 200px);
  }
  .el-form-item__label {
    line-height: 40px;
  }
  .el-button.is-disabled.el-button--text {
    text-decoration: line-through;
  }
}
</style>
